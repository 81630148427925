import React from "react";
import { UIContext } from "@Contexts/ui";

const useUI = () => {
    const context = React.useContext(UIContext);
    if (context === undefined) {
        throw new Error("useUI must be used within a UIProvider");
    }

    return context;
};

export default useUI;