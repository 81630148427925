import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from '@Services/api/utils/ProtectedRoute';
import NotificationProvider from '@Services/ws/NotificationProvider';
import Loader from '@Components/Loader';
import UIProvider from '@Contexts/ui';
import { PATH } from '@Data/screens';

const Start = React.lazy(() => import('./screens/Start'));
const Login = React.lazy(() => import('./screens/Login'));
const Register = React.lazy(() => import('./screens/Register'));
const ResetPassword = React.lazy(() => import('./screens/ResetPassword'));
const Congratulations = React.lazy(() => import('./screens/Congratulations'));

const Home = React.lazy(() => import('./screens/Home'));
const Map = React.lazy(() => import('./screens/Map'));
const CV = React.lazy(() => import('./screens/CV'));
const Duels = React.lazy(() => import('./screens/Duels'));
const Profile = React.lazy(() => import('./screens/Profile'));
const Play = React.lazy(() => import('./screens/Play'));

const CreateDuel = React.lazy(() => import('./screens/CreateDuel'));
const DuelStatus = React.lazy(() => import('./screens/DuelStatus'));
const DuelArena = React.lazy(() => import('./screens/DuelArena'));
const DuelTutorial = React.lazy(() => import('./screens/DuelTutorial'));
const CVResult = React.lazy(() => import('./screens/CVResult'));

const App = () => {
  return (
    <UIProvider>
      <NotificationProvider>
        <BrowserRouter>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route
                path={PATH.START}
                element={
                  <ProtectedRoute redirectAuthorized element={<Start />} />
                }
              />
              <Route
                path={PATH.LOGIN}
                element={
                  <ProtectedRoute redirectAuthorized element={<Login />} />
                }
              />
              <Route
                path={PATH.REGISTER}
                element={
                  <ProtectedRoute redirectAuthorized element={<Register />} />
                }
              />
              <Route
                path={PATH.REMIND_PASSWORD}
                element={
                  <ProtectedRoute
                    redirectAuthorized
                    element={<ResetPassword />}
                  />
                }
              />
              <Route
                path={PATH.REGISTER_COMPLETE}
                element={<ProtectedRoute element={<Congratulations />} />}
              />

              <Route
                path={PATH.HOME}
                element={<ProtectedRoute element={<Home />} />}
              />
              <Route
                path={PATH.MAP}
                element={<ProtectedRoute element={<Map />} />}
              />
              <Route
                path={PATH.CV}
                element={<ProtectedRoute element={<CV />} />}
              />
              <Route
                path={PATH.DUELS}
                element={<ProtectedRoute element={<Duels />} />}
              />
              <Route
                path={PATH.PROFILE}
                element={<ProtectedRoute element={<Profile />} />}
              />
              <Route
                path={PATH.PLAY}
                element={<ProtectedRoute element={<Play />} />}
              />
              <Route
                path={PATH.CREATE_DUEL}
                element={<ProtectedRoute element={<CreateDuel />} />}
              />
              <Route
                path={PATH.DUEL_STATUS}
                element={<ProtectedRoute element={<DuelStatus />} />}
              />
              <Route
                path={PATH.DUEL_ARENA}
                element={<ProtectedRoute element={<DuelArena />} />}
              />
              <Route path={PATH.DUEL_TUTORIAL} element={<DuelTutorial />} />
              <Route
                path={PATH.COUNT_RESULT}
                element={<ProtectedRoute element={<CVResult />} />}
              />
              <Route path="/" element={<Navigate to={PATH.START} replace />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </NotificationProvider>
    </UIProvider>
  );
};

export default App;
