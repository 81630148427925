export const SCREENS = {

};

export const PATH = {
    START: "/start",
    LOGIN: "/login",
    REGISTER: "/register",
    REMIND_PASSWORD: "/reset-password",
    REGISTER_COMPLETE: "/congratulations",
    HOME: "/home",
    MAP: "/map",
    CV: "/train",
    DUELS: "/duels",
    PROFILE: "/profile",
    PLAY: "/play",
    CREATE_DUEL: "/create-duel",
    DUEL_STATUS: '/duel-status',
    DUEL_ARENA: '/arena',
    DUEL_TUTORIAL: '/tutorial',
    COUNT_RESULT: "/results"
}