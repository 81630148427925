import { FC, useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import useUI from '@Hooks/useUI';
import { WS_URL } from '@Services/config';

import 'react-toastify/dist/ReactToastify.css';

const NotificationProvider: FC<{ children: any }> = ({ children }) => {
  const { isAuthorized, userId } = useUI();
  const [ws, setWs] = useState<WebSocket | null>(null);

  const notify = (title: string, text: string) => {
    toast(
      <div>
        <h2 className="text-lg font-semibold text-tint">{title}</h2>
        <p className="text-sm">{text}</p>
      </div>,
    );
  };

  useEffect(() => {
    const createWS = () => {
      if (!userId && !isAuthorized) return;

      console.log('[WS Notification]', 'Create WebSocket', userId);

      const wsConnection: WebSocket = new WebSocket(
        `${WS_URL}/notifications/${userId}/`,
      );
      setWs(wsConnection);
    };

    createWS();

    return () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ sentinel: 'STOP' }));
        ws.close();
      }
    };
  }, []);

  useEffect(() => {
    if (!ws) return;

    ws.onopen = () => {
      console.log('[WS Notification]', 'Connection opened');
    };

    ws.onclose = () => {
      console.log('[WS Notification]', 'Connection closed');
    };

    ws.onerror = (e) => {
      console.error('[WS Notification]', 'Connection error', e);
    };

    ws.onmessage = (e) => {
      console.log('[WS Notification]', 'Received message', e.data);
      const data = JSON.parse(e.data);

      let title: string = '';
      let subtitle: string = '';

      if (data.action === 'updated') {
        title = 'Divine Gains';
        subtitle = `Zeus is pleased! You've earned experience points for your fitness efforts.`;
      }

      if (data.action === 'level up') {
        title = 'Olympian Rise';
        subtitle = `With the might of Zeus, you have unlocked a ${data.current} level in your fitness journey.`;
      }

      if (data.type === 'duel') {
        title = 'Godly Challenge';
        subtitle = `${data.details.challenger} has invited you to a mighty duel. Will you answer the call?`;
      }

      if (data.action === 'verdict') {
        title = 'Divine Verdict';
        subtitle = data.value;
      }

      notify(title, subtitle);
    };

    return () => {
      ws.close();
    };
  }, [ws]);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnHover
        theme="dark"
      />
      {children}
    </>
  );
};

export default NotificationProvider;
