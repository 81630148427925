import React, { useEffect, useReducer } from 'react';
import useLocalStorage from '@Hooks/useLocalStorage';

export interface IState {
  isAuthorized: boolean;
  username: string;
  userId: number;
  experience: number;
  level: number;
  currentMission: number;
}

const initialState: IState = {
  isAuthorized: false,
  username: '',
  userId: 0,
  experience: 0,
  level: 1,
  currentMission: 0,
};

type Action =
  | {
      type: 'SET_AUTHORIZED';
    }
  | {
      type: 'SET_UNAUTHORIZED';
    }
  | {
      type: 'SET_USERNAME';
      value: string;
    }
  | {
      type: 'SET_USER_ID';
      value: number;
    }
  | {
      type: 'SET_EXPERIENCE';
      value: number;
    }
  | {
      type: 'SET_LEVEL';
      value: number;
    }
  | {
      type: 'SET_CURRENT_MISSION';
      value: number;
    };

export const UIContext = React.createContext<any>(initialState);

UIContext.displayName = 'UIContext';

const uiReducer = (state: IState, action: Action): IState => {
  switch (action.type) {
    case 'SET_AUTHORIZED':
      return {
        ...state,
        isAuthorized: true,
      };
    case 'SET_UNAUTHORIZED':
      return {
        ...state,
        isAuthorized: false,
      };
    case 'SET_USERNAME':
      return {
        ...state,
        username: action.value,
      };
    case 'SET_USER_ID':
      return {
        ...state,
        userId: action.value,
      };
    case 'SET_EXPERIENCE':
      return {
        ...state,
        experience: action.value,
      };
    case 'SET_LEVEL':
      return {
        ...state,
        level: action.value,
      };
    case 'SET_CURRENT_MISSION':
      return {
        ...state,
        currentMission: action.value,
      };
    default:
      return state;
  }
};

export const UIProvider: React.FC<any> = (props) => {
  const [localState, setLocalState] = useLocalStorage<IState>(
    'uiState',
    initialState,
  );
  const [state, dispatch] = useReducer(uiReducer, localState);

  const authorize = () => dispatch({ type: 'SET_AUTHORIZED' });
  const unauthorize = () => dispatch({ type: 'SET_UNAUTHORIZED' });
  const setUsername = (value: string) =>
    dispatch({ type: 'SET_USERNAME', value });
  const setUserId = (value: number) => dispatch({ type: 'SET_USER_ID', value });
  const setExperience = (value: number) =>
    dispatch({ type: 'SET_EXPERIENCE', value });
  const setLevel = (value: number) => dispatch({ type: 'SET_LEVEL', value });
  const setCurrentMission = (value: number) =>
    dispatch({ type: 'SET_CURRENT_MISSION', value });

  useEffect(() => {
    setLocalState(state);
  }, [state]);

  useEffect(() => {
    const access_token = localStorage.getItem('access_token');
    const refresh_token = localStorage.getItem('refresh_token');

    if (!access_token || !refresh_token) {
      unauthorize();
      setCurrentMission(0);
    }
  }, []);

  const value = React.useMemo(
    () => ({
      ...state,
      authorize,
      unauthorize,
      setUsername,
      setUserId,
      setExperience,
      setLevel,
      setCurrentMission,
    }),
    [state],
  );

  return <UIContext.Provider value={value} {...props} />;
};

export default UIProvider;
