import { Navigate, RouteProps } from 'react-router-dom';
import { PATH } from '@Data/screens';
import useUI from '@Hooks/useUI';

type TProtectedRoute = RouteProps & { redirectAuthorized?: boolean };

const ProtectedRoute: React.FC<TProtectedRoute> = ({
  redirectAuthorized = false,
  element,
}) => {
  const { isAuthorized } = useUI();

  if (redirectAuthorized) {
    return isAuthorized ? <Navigate to={PATH.HOME} replace /> : element;
  }

  return isAuthorized ? element : <Navigate to={PATH.LOGIN} replace />;
};

export default ProtectedRoute;
